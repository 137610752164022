<!-- Fonts -->
<!-- <link href="https://fonts.googleapis.com/css?family=Raleway:100,600" rel="stylesheet" type="text/css"> -->

<template>
  <Head title="Welcome" />

  <div id="mainW" >
    <div v-if="canLogin" class="top-right linksW">
      <Link v-if="$page.props.auth.user" :href="route('home')">
        Dashboard
      </Link>

      <template v-else>
        <Link :href="route('login')">
          Log in
        </Link>

        <Link v-if="canRegister" :href="route('register')">
          Register
        </Link>
      </template>
    </div>

    <div class="content" id="divCenter">
      <div class="title m-b-md">
        Campus Cloud v2
      </div>
      <div class="linksW">
        <a href="http://campuscloudservices.com">Web Site</a>
        <a href="http://campus.support/">Support</a>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { Head, Link } from '@inertiajs/vue3';

export default defineComponent({
	components: {
		Head,
		Link,
	},

	props: {
		canLogin: Boolean,
		canRegister: Boolean,
		laravelVersion: String,
		phpVersion: String,
	}
})
</script>

<style>
  #divCenter{
      position: absolute;
      top: 50%;
      left: 50%;
      margin-top: -150px;
      margin-left: -350px;
      width: 700px;
      height: 300px;
	}

  #mainW {
    font-family: 'Raleway', sans-serif;
    font-weight: 100;
    height: 100vh;
    margin: 0;
  }

  .full-height {
    height: 100vh;
  }

  .flex-center {
    align-items: center;
    display: flex;
    justify-content: center;
  }

  .position-ref {
    position: relative;
  }

  .top-right {
    position: absolute;
    right: 10px;
    top: 18px;
  }

  .content {
    text-align: center;
  }

  .title {
    font-size: 84px;
  }

  .linksW > a {
    color: #636b6f;
    padding: 0 25px;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: .1rem;
    text-decoration: none;
    text-transform: uppercase;
  }

  .m-b-md {
    margin-bottom: 30px;
  }
</style>
